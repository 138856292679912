import '~/styles/application.css'

import { createApp, h } from 'vue'
import { createI18n } from 'vue-i18n'
import { createInertiaApp } from '@inertiajs/vue3'
import '~/utils/viewportHeight'

import en from '~/locales/en.json'
import es from '~/locales/es.json'

const i18n = createI18n({
  legacy: false,
  locale: window.locale,
  fallbackLocale: 'en',
  messages: {
    ...en,
    ...es,
  },
})

createInertiaApp({
  resolve: name => {
    const pages = import.meta.glob('../Pages/**/*.vue')
    return pages[`../Pages/${name}.vue`]()
  },
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(i18n)
      .mount(el)
  },
})