{
  "es": {
    "account": {
      "info": {
        "cta": "Actualizar datos",
        "error_email": "El email ya ha sido registrado antes.",
        "input_one": "Nombre",
        "input_two": "Correo electrónico",
        "title": "Actualiza tu información básica"
      },
      "password": {
        "cta": "Actualizar contraseña",
        "input_one": "Contraseña actual",
        "input_two": "Nueva contraseña",
        "title": "Cambio de contraseña"
      },
      "title": {
        "main": "Configuración cuenta",
        "subtitle": "Administra los datos asociados a tu cuenta"
      }
    },
    "activerecord": {
      "errors": {
        "models": {
          "event": {
            "attributes": {
              "photo": {
                "not_image": "debe ser un formato de imagen válida (.jpg, .png, .webp)",
                "too_large": "no debe ser mayor a 5mb"
              }
            }
          },
          "user": {
            "attributes": {
              "password_confirmation": {
                "confirmation": "Oops, las contraseñas no coinciden"
              },
              "reset_password_token": {
                "invalid": "El token de recuperación es invalido o ha expirado"
              }
            }
          }
        }
      }
    },
    "authentication": {
      "forgot_password": {
        "cta": "Enviar instrucciones para restablecer tu contraseña.",
        "error": "La dirección de correo electrónico ingresada no se encuentra en nuestro registro.",
        "textbox": "Ingresa la dirección de correo electrónico asociada a tu cuenta.",
        "title": "Olvidaste tu contraseña?"
      },
      "login": {
        "cta": {
          "forgot_password": "Olvidaste tu contraseña?",
          "login": "Iniciar sesión",
          "sign_up": "No tienes una cuenta?",
          "sign_up_link": "Crea tu cuenta."
        }
      },
      "signup": {
        "cta": {
          "create": "Crear mi cuenta",
          "login": "¿Ya tienes una cuenta?",
          "login_link": "Inicia sesión."
        },
        "labels": {
          "confirm_password": "Confirmar Contraseña",
          "email": "Email",
          "email_validation": "Hmmm... eso no parece un email (error)",
          "name": "Nombre",
          "password": "Contraseña",
          "password_validation": "Oops, las contraseñas no coinciden (error)"
        },
        "title": "Crea tu cuenta"
      }
    },
    "birthday_person": {
      "cta": {
        "modal": "Regresar a obsequios",
        "title": "¡Ahora es tu turno de crear tu evento en Birthday Hello!"
      },
      "textbox": "Es hora de celebrar y empezar a abrir los obsequios de todas las personas que te quieren. Solo has clic en cada una de las cajas para averiguar qué hay dentro!",
      "title": {
        "principal": "Bienvenid{'@'} a tu cumpleaños {age} 🎁🎉",
        "welcome": "Hola ✋ {birthday_person}"
      }
    },
    "event_card": {
      "awaiting": "Esperando",
      "gift": "regalo",
      "gifts": "regalos",
      "open": "Abrir",
      "received": "recibidos",
      "sample": {
        "create": "crear evento",
        "tag": "Ejemplo"
      },
      "view": "Ver"
    },
    "guest": {
      "confirmation": {
        "cta": {
          "button": "Crea el tuyo",
          "textbox": "¡Ahora es tu turno de crear tu evento en Birthday Hello! ¿Qué estás esperando?",
          "title": "¡Es hora de crear tu celebración!"
        },
        "textbox": "¡Hemos recibido todos tus obsequios! Ponte en contacto con el organizador del evento si necesitas hacer algún cambio o para preguntas adicionales.",
        "title": "¡Obsequios enviados!"
      },
      "wizard": {
        "confirmation_step": {
          "cta": {
            "confirm": "Confirmar y enviar",
            "top": "Inicio"
          },
          "disclaimer": {
            "and": "y",
            "info": "Nunca venderemos tu información a nadie.",
            "intro": "Al enviar este formulario estás aceptando nuestros",
            "privacy_policy": "Políticas de Privacidad",
            "terms_of_service": "Términos de Servicio"
          },
          "message_function": {
            "add": "Agregar Mensaje",
            "message": "Tu mensaje"
          },
          "summary_function": {
            "change": "Cambiar",
            "gifts": "Tus obsequios"
          },
          "textbox": "Revisa de nuevo antes de enviar. ¡Una vez enviados, el organizador recibirá una notificación con tu envío!",
          "title": "Confirma tus obsequios 🎁🎁🎁️"
        },
        "email_step": {
          "cta": "Continuar",
          "label": "Ingresa tu correo electrónico",
          "textbox": "¡No te preocupes! Jamás venderemos tu información.",
          "title": "¿CUÁL ES TU EMAIL? 📫📫"
        },
        "message_step": {
          "cta": {
            "continue": "Continuar",
            "skip": "O saltar este paso",
            "upload": "Subir mensaje"
          },
          "placeholder": {
            "left": "{charactersLeft} caracteres restantes",
            "message": "Escribe tu mensaje aquí..."
          },
          "textbox": "Escribe un mensaje que quieras compartir con {birthday_person} en su cumpleaños.",
          "title": "¿QUIERES ENVIAR UN MENSAJE? 💬"
        },
        "name_step": {
          "cta": "Continuar",
          "placeholder": "Coloca tu nombre o el nombre de tu grupo",
          "textbox": "Si estás enviando el mensaje como parte de un grupo/familia, puedes usar algo como “Familia Rodríguez” por ejemplo.",
          "title": "Hola! 🖐️ ¿Cómo te llamas?"
        },
        "photo_step": {
          "cta": {
            "continue": "Continuar",
            "skip": "O saltar este paso",
            "upload": "Subir fotos",
            "uploading": "Subiendo..."
          },
          "textbox": "Si tienes fotos con el/la cumpleañer{'@'}, ¡este es el lugar para que las puedas subir! Sube hasta 5 fotos.",
          "title": "¿QUIERES COMPARTIR ALGUNAS FOTOS? 🖼 📷",
          "upload_function": {
            "info": "Sube hasta 5 fotos.",
            "pick": "Escoge tus recuerdos favoritos."
          }
        },
        "video_step": {
          "cta": {
            "continue": "Continuar",
            "skip": "O saltar este paso",
            "upload": "Subir video",
            "uploading": "Subiendo..."
          },
          "textbox": "Sube tus felicitaciones en video.",
          "title": "¿Quieres compartir un VIDEO? 📽",
          "upload_function": {
            "error": "Error al tratar de subir el video. Por favor, intenta agregar el archivo de nuevo...",
            "info": "Formatos de video soportados .mov, .mp4, .mpeg y otros. Deben tener un tamaño menor a 300mb",
            "loader": "Solo un momento más..."
          }
        },
        "welcome_step": {
          "cta": {
            "create_your_own": "Crea el tuyo",
            "start": "Empezar"
          },
          "send_by": "Enviar para el",
          "textbox": "Hola, estoy organizando un Birthday Hello para el cumpleaños {age} de {birthday_person} y necesito la ayuda de todos. Es muy simple, solo envía tu video y fotos con tus deseos de cumpleaños. Así serás parte de esta sorpresa inolvidable. Y recuerden, ella no sabe nada. ¡Gracias!",
          "title": "Hey! 🖐 BIENVENID{'@'} al cumpleaños {age} de {birthday_person} 🎁🎉"
        }
      }
    },
    "host": {
      "empty_space": {
        "textbox": "Es hora de invitar a otros a homenajear a {birthday_person}. Solo comparte el enlace con otros invitados.",
        "title": "¡No hay nadie aquí aún!"
      },
      "event_dashboard": {
        "dashboard": {
          "cta": "Crear evento",
          "textbox": "Crea, administra y organiza tus eventos.",
          "title": "¡Hola Alma de la fiesta!"
        },
        "disclaimer": {
          "end": " automáticamente 7 días después de la fecha del evento.",
          "link": "se ocultan",
          "start": "Los eventos "
        },
        "history": {
          "hide": "Ocultar",
          "show": "Mostrar",
          "text": " mis eventos pasados"
        },
        "tiles": {
          "countdown": "Hace {deadline} dias",
          "cta": "Ver",
          "states": {
            "draft": "Borrador",
            "published": "Publicado",
            "sample": "Ejemplo",
            "shared": "Compartido"
          }
        }
      },
      "event_manager": {
        "date_picker": {
          "limit": "Envíos permitidos hasta las 11:59 pm",
          "when": "¿Reabrir hasta cuando?"
        },
        "deadline": {
          "after": "Hace",
          "before": "En",
          "day": "dia",
          "days": "dias",
          "same": "Hoy",
          "tomorrow": "1 dia"
        },
        "delete_submission_modal": {
          "cancel": "Cancelar",
          "confirm": "Si, eliminar",
          "title": "Eliminar este obsequio",
          "warning": "¿Estás seguro de eliminar este obsequio con todos los videos, fotos y mensajes incluidos?"
        },
        "event_notifications": {
          "deleted": "Evento Eliminado",
          "open": "Evento abierto a invitaciones",
          "published": "Evento Publicado"
        },
        "event_options": {
          "are_you_sure": "Estas seguro?",
          "delete": "Eliminar",
          "edit": "Editar",
          "pay": "Pagar",
          "publish": "Publicar",
          "reopen": "Reabrir"
        },
        "gift_modal": {
          "close": "Volver a obsequios",
          "confirmation": "¿Estás seguro?",
          "delete_gift": "Borrar este obsequio",
          "remove": "Quitar",
          "text": "Tienes la posibilidad de quitar los archivos que no quieras que sean publicados como parte del obsequio."
        },
        "invite_actions": {
          "copied": "Copiado!",
          "copy": "Copiar",
          "hide": "Ocultar"
        },
        "labels": {
          "cta": {
            "copied": "Copiado",
            "copy_link": "Copiar link",
            "publish_event": "Publicar Evento"
          },
          "deadline": "Fecha límite",
          "guests": "Invitados",
          "invite": "Invitar",
          "none_yet": "Ninguno aún"
        },
        "lets_party": {
          "hide": "Esconder",
          "note": "Nota: Cualquier persona con el enlace podrá ver todos los obsequios enviados.",
          "textbox": "¡El evento está en vivo! Utiliza el siguiente enlace público para compartirlo con {birthday_person} y los demás.",
          "title": "¡A Celebrar!"
        },
        "options_menu": {
          "are_you_sure": "Estás segur{'@'}?",
          "delete": "Eliminar",
          "edit": "Editar",
          "publish": "Publicar",
          "reopen": "Reabrir",
          "sr": "Abrir menu de opciones"
        },
        "payment_confirmation_modal": {
          "content": "Tu pago ha sido confirmado, y el \"magic link\" está listo para que empieces a enviar invitaciones ilimitadas.",
          "cta": "Estoy listo",
          "title": "¡Todo está listo!"
        },
        "payment_modal": {
          "content": "Completa tu pago para desbloquear el \"magic link\". Una vez hecho, tendrás invitaciones ilimitadas para recoger los mejores deseos para {birthday_person}!",
          "cta": "Ir al pago",
          "title": "¡La fiesta está a punto de comenzar!"
        },
        "payment_reminder_banner": {
          "content_bold": "completar el pago",
          "content_normal": "Ya casi! No te olvides de",
          "prefix": "¡Oye, oye!",
          "suffix": "para poder publicar y compartir tu evento."
        },
        "texbox": "Hola, estoy creando un Birthday Hello para el cumpleaños {age} de {birthday_person} y necesito de la ayuda de todos. Por favor, envíen sus fotos y video con sus deseos de cumpleaños (es simple). Serán parte de una sorpresa inolvidable. Recuerden, el / ella no sabe nada. ¡Gracias!",
        "tiles": {
          "from": "De",
          "open": "Abrir"
        },
        "title": "Cumpleaños {age} de {birthday_person} 🎁🎉"
      },
      "inactive_event": {
        "textbox": "Lo sentimos… No queremos ser unos aguafiestas, pero este evento ya no se encuentra activo. Por favor, contacta al organizador para más información.",
        "title": "Opsss"
      },
      "new_event": {
        "age_selector": {
          "dont_know": "No estoy segur{'@'}",
          "year": "año",
          "years": "años"
        },
        "characters_left": "{charactersLeft} caracteres restantes",
        "cta": {
          "cancel": "Cancelar",
          "clear": "Limpiar",
          "create": "Crear un evento",
          "payment_notification": "Serás redirigido a la pantalla de pago",
          "picture": "Foto cumpleañer{'@'}",
          "save": "Guardar",
          "upload_photo": "Subir Foto"
        },
        "disclaimer": "Los contenidos serán admitidos hasta la medianoche EST.",
        "file_size_error": "La foto es muy pesada (Max {file_size})",
        "labels": {
          "age": "¿Años? (Opcional)",
          "birthday_person": "Homenajeado",
          "deadline": "Fecha límite",
          "message": "Mensaje de Invitación",
          "name": "Nombre del evento",
          "sample_texts": "O selecciona uno de los mensajes existentes",
          "theme": "Tema"
        },
        "message_placeholder": "Escribe aqui tu mensaje...",
        "sample_texts": {
          "formal": "Formal",
          "fun": "Divertido",
          "loving": "Navidad"
        },
        "textbox": "¡Empecemos con los detalles básicos!",
        "title": {
          "default": "¡Crea un nuevo evento! 🎉🎉🎉",
          "edition": "¡Edita tu evento! 🎉🎉🎉"
        }
      },
      "passed_event": {
        "textbox": "Lo sentimos… No queremos ser unos aguafiestas, pero la fecha límite para este evento ya ha pasado. Por favor, contacta al organizador para más información.",
        "title": "Opsss"
      }
    }
  }
}